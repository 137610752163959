import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/browser";

import { FirebaseApp } from "@firebase/app";

import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import "@fontsource/jetbrains-mono";
import "typeface-rubik";

import {
    CircularProgressCenter,
    ErrorView,
    ModeControllerProvider,
    NotFoundPage,
    SnackbarProvider,
    useBuildModeController
} from "@firecms/core";

import { CenteredView } from "@firecms/ui";
import {
    FireCMSBackend,
    FireCMSBackEndProvider,
    FireCMSCloudLoginView,
    useBuildFireCMSBackend,
    useInitialiseFirebase
} from "@firecms/cloud";

import { backendFirebaseConfig } from "./backend_firebase_config";
import { NewFireCMSProjectStart } from "./routes/NewFireCMSProjectStart";
import { SassDebugView } from "./routes/SassDebugView";
import { NewGoogleCloudProjectFlow } from "./routes/NewGoogleCloudProjectFlow";
import { SaasCMSAppClient } from "./routes/SaasCMSAppClient";
import { FireCMSDoctorProjectPage } from "./routes/FireCMSDoctorProjectPage";
import { SaasMainPage } from "./routes/SaasMainPage";
import { SaasScaffold } from "./components/SaasScaffold";
import { FireCMSDoctorListPage } from "./routes/FireCMSDoctorListPage";
import { SelfHostedSubscriptions } from "./routes/SelfHostedSubscriptions";
import { SaasAnalyticsProvider, useSaasAnalytics } from "./components/SaasAnalyticsProvider";
import { UserSubscriptions } from "./routes/UserSubscriptions";
import { FireCMSCLIView } from "./cli/cli";
import { SaasApp } from "./SaasApp";


export function App() {

    const {
        firebaseApp: backendFirebaseApp,
        firebaseConfigLoading,
        configError,
        firebaseConfigError
    } = useInitialiseFirebase({
        name: "firecms-backend",
        firebaseConfig: process.env.NODE_ENV !== "production" ? backendFirebaseConfig : undefined,
        authDomain: backendFirebaseConfig.authDomain
    });

    const modeController = useBuildModeController();

    if (firebaseConfigLoading || !backendFirebaseApp) {
        return <CircularProgressCenter/>;
    }

    if (firebaseConfigError) {
        return <ErrorView
            error={firebaseConfigError}/>
    }

    if (configError) {
        return <ErrorView
            error={configError}/>
    }


    return (
        <Router>
            <SaasAnalyticsProvider backendFirebaseApp={backendFirebaseApp}>
                <ModeControllerProvider value={modeController}>
                    <SaasApp backendFirebaseApp={backendFirebaseApp}/>
                </ModeControllerProvider>
            </SaasAnalyticsProvider>
        </Router>
    );
}
