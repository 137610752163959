import React, { PropsWithChildren } from "react";
import { AppBar, Scaffold } from "@firecms/core";
import { SaasCMSAppBar } from "./SaasAppBar";

export const DRAWER_WIDTH = 280;

/**
 * @group Core
 */
export interface SaasScaffoldProps {
    includeProjectSelect?: boolean;
}

/**
 *
 * @param props

 * @group Core
 */
export const SaasScaffold = function SaasScaffold(props: PropsWithChildren<SaasScaffoldProps>) {

    const {
        children,
        includeProjectSelect,
    } = props;

    return <Scaffold>
        <AppBar>
            <SaasCMSAppBar title={"FireCMS Cloud"}
                           includeProjectSelect={false}/>
        </AppBar>
        {children}
    </Scaffold>;
};
